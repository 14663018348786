<template>
  <section class="conOfAnnualMeeting h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'SSBD', part2: 'Awards' }" />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row>
          <v-col
            md="3"
            sm="6"
            cols="12"
            v-for="(item, index) in listItems"
            :key="index"
          >
            <div class="conofcard">
              <v-card class="mx-auto" max-width="370" tile elevation="0">
                <div class="conOfCardImg">
                  <div class="conAwardInsideImages d-flex align-center">
                    <div class="leftImgAward">
                      <img
                        src="@/assets/img/awardLeft.svg"
                        alt="item of the Image"
                        class="img-fluid"
                      />
                    </div>
                    <div class="awardContent">
                      <img :src="item.image" alt="item of the Image" class="img-fluid" />
                    </div>

                    <div class="rightImgAward">
                      <img
                      class="img-fluid"
                        src="@/assets/img/awardRight.svg"
                        alt="item of the Image"
                      />
                    </div>
                  </div>
                </div>

                <div class="cardTitle text-center">
                  <div class="titleSec">
                    {{ item.title }}
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "Research",
        disabled: true,
      },
      {
        text: "Awards",
        disabled: false,
      },
    ],
    listItems: null,
    isLoading: false,
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    getListData() {
      this.listItems = [];
      this.isLoading = true;

      apiServices.get("awards").then((res) => {
        if (res) {
          this.listItems = res.data;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_awards.scss";
</style>
